<template>
  <div>
    <p>
      {{ $t("you_have_unsaved_changes") }}
    </p>
    <Checkbox
      id="dont-ask-again-changes"
      class="text-center"
      style="font-size: 0.9em"
      :checked="ask"
      @change="$emit('input', $event)"
    >
      {{ $t("dont_ask_again") }}
    </Checkbox>
  </div>
</template>
<script>
import Checkbox from "@/components/base/checkbox";
import Vue from "vue";

export default Vue.extend({
  name: "AlertDiscardChanges",
  components: { Checkbox },
  props: {
    ask: { type: Boolean, default: false }
  }
});
</script>
